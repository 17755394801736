import { ZoomInOutlined } from '@ant-design/icons';
import { Button, Card } from 'antd';
import { map } from 'lodash';
import React, { useState } from 'react';
import PlaceHolderImageMaster from '../assets/svg/place-holder-image-master.svg';
import {
  HORIZONTAL_PADDING,
  PORTAL_WIDTH,
  PRIMARY_COLOR,
} from '../common/constants';
import ModalComponent from './ModalComponent';
import PickListCheckedIconComponent from './PickListCheckedIconComponent';
import './styles/CommonPickListWidget.less';

const ImageBlock = ({
  item = null,
  defaultCheck = false,
  imageUrl = null,
  primaryColor = null,
}) => {
  if (!defaultCheck) {
    return (
      <>
        <img
          alt="logo"
          className="card-image"
          src={imageUrl || PlaceHolderImageMaster}
          onError={(e) => {
            e.target.src = PlaceHolderImageMaster;
          }}
          height={75}
        />
        <span className="card-title" title={item?.label}>
          {item?.label}
        </span>
      </>
    );
  }
  return (
    <>
      <PickListCheckedIconComponent color={primaryColor} />
      <span className="card-title" title={item?.label}>
        {item?.label}
      </span>
    </>
  );
};

const WithoutImageBlock = ({ defaultCheck = false, item = null }) => {
  if (!defaultCheck) {
    return (
      <span className="card-title" title={item?.label}>
        {item?.label}
      </span>
    );
  }
  return (
    <span className="card-title" title={item?.label}>
      {item?.label}
    </span>
  );
};

const CommonPickListWidget = (props) => {
  const {
    gridClassName = '',
    displayStyle = 'TILES',
    renderItems = [],
    renderImages = false,
    imagePreview = false,
    renderDescriptions = false,
    isMultiple = false,
    selectedKey = null,
    multipleKeys = [],
    rules = null,
    keyName = 'blockId', // specify name of unique property name from our object e.g blockId or uuid,
    handleRedirect,
    primaryColor = PRIMARY_COLOR,
  } = props;

  const [showPreview, setShowPreview] = useState(false);
  const [record, setRecord] = useState(false);

  const closeModel = () => {
    setRecord(null);
    setShowPreview(false);
  };

  const showPreviewModal = (item) => {
    setRecord(item);
    setShowPreview(true);
  };

  return (
    <div
      className={`common-picklist ${rules?.readOnly && 'pointer-event-none'} `}
    >
      {renderItems?.length > 0 && (
        <Card
          className={`${renderItems?.length === 1 ? 'center-aligned' : ''} `}
        >
          {map(renderItems, (item) => {
            let defaultCheck = false;

            if (isMultiple) {
              defaultCheck = multipleKeys?.includes(item?.[keyName]);
            } else {
              defaultCheck = selectedKey === item?.[keyName];
            }
            return (
              <Card.Grid
                onClick={() => {
                  handleRedirect(item);
                }}
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e?.key === 'Enter') {
                    if (handleRedirect) {
                      handleRedirect(item);
                    }
                  }
                }}
                key={item?.key}
                className={`grid-card ${
                  displayStyle === 'TILES' ? 'tile-view' : ''
                }${displayStyle === 'LIST' ? 'list-view' : ''} ${
                  defaultCheck && 'card-selected'
                }${gridClassName || ''}`}
                hoverable
              >
                <div className="d-flex flex-vertical align-center">
                  {renderImages && (
                    <>
                      <ImageBlock
                        item={item}
                        imageUrl={item?.image?.url || PlaceHolderImageMaster}
                        defaultCheck={defaultCheck}
                        primaryColor={primaryColor}
                      />
                    </>
                  )}

                  {!renderImages && (
                    <WithoutImageBlock
                      item={item}
                      defaultCheck={defaultCheck}
                      primaryColor={primaryColor}
                    />
                  )}
                  {renderDescriptions && (
                    <span
                      className="card-description line-clamp"
                      title={item?.blockDescription || item?.description}
                    >
                      {item?.blockDescription || item?.description}
                    </span>
                  )}
                  {imagePreview && (
                    <span
                      className={`zoom-image ${
                        selectedKey === item?.key || defaultCheck
                          ? 'color-white'
                          : 'color-primary'
                      } `}
                      title="zoom-image"
                      onClick={(e) => {
                        e?.stopPropagation();
                        showPreviewModal(item);
                      }}
                    >
                      {renderDescriptions ? (
                        'See More'
                      ) : (
                        <>
                          <ZoomInOutlined /> zoom
                        </>
                      )}
                    </span>
                  )}
                </div>
              </Card.Grid>
            );
          })}
        </Card>
      )}
      {showPreview && (
        <ModalComponent
          open={showPreview}
          footer={null}
          onCancel={closeModel}
          destroyOnClose
          width={PORTAL_WIDTH - 2 * HORIZONTAL_PADDING}
          wrapClassName="preview-modal"
        >
          <>
            <div className="modal-title">
              <span>{record?.label}</span>
            </div>
            <div className="d-flex justify-center">
              <img
                alt="logo"
                className="modal-image"
                src={record?.image?.url || PlaceHolderImageMaster}
                onError={(e) => {
                  e.target.src = PlaceHolderImageMaster;
                }}
              />
            </div>
            <div className="d-flex justify-center mb-16">
              <span>{record?.blockDescription}</span>
            </div>
            <Button
              className="fill-width"
              type="primary"
              onClick={() => {
                handleRedirect(record);
                setShowPreview(false);
              }}
            >
              Select
            </Button>
          </>
        </ModalComponent>
      )}
    </div>
  );
};

export default CommonPickListWidget;
