import { LoadingOutlined } from '@ant-design/icons';
import * as Sentry from '@sentry/react';
import { ConfigProvider, Spin } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AppContext } from './AppContext';
import Error404 from './Error404';
import App from './app/App';
import { ROUTES } from './common/constants';
import MaintenancePage from './components/MaintenancePage';
import { history } from './historyData';

const RoutesWrapper = () => {
  const {
    state: { primaryColor },
  } = useContext(AppContext);
  const [cookiesEnabled, setCookiesEnabled] = useState(true);
  const MyFallbackComponent = ({ error, componentStack }) => (
    <div>
      <p>
        <strong>Oops! An error occured!</strong>
      </p>
      <p>Below is the details…</p>
      <p>
        <strong>Error:</strong> {error?.toString()}
      </p>
      <p>
        <strong>Stacktrace:</strong> {componentStack}
      </p>
    </div>
  );

  useEffect(() => {
    const checkCookieSupport = () => {
      // eslint-disable-next-line no-undef
      document.cookie = 'testcookie=1';
      // eslint-disable-next-line no-undef
      const cookiesSupported = document.cookie.indexOf('testcookie') !== -1;
      // eslint-disable-next-line no-undef
      document.cookie = 'testcookie=1; expires=Thu, 01 Jan 1970 00:00:00 GMT';
      return cookiesSupported;
    };

    const checkStorageAccess = async () => {
      // eslint-disable-next-line no-undef
      if (typeof document.hasStorageAccess === 'function') {
        // Older Version of Chrome before 119 Does not support hasStorageAccess Therefore adding a fallback using try and catch
        try {
          // eslint-disable-next-line no-undef
          const hasAccess = await document.hasStorageAccess();
          setCookiesEnabled(hasAccess);
        } catch (error) {
          setCookiesEnabled(checkCookieSupport());
        }
      } else {
        setCookiesEnabled(checkCookieSupport());
      }
    };

    checkStorageAccess();
  }, []);

  Spin?.setDefaultIndicator(<LoadingOutlined className="font-size-32" spin />);

  // use this variable from envs so that we can able to run maintenance page on runtime.
  const maintenance = process.env.REACT_APP_MAINTENANCE_ENABLE;

  if (maintenance === 'true') {
    return <MaintenancePage />;
  }

  if (!cookiesEnabled) {
    return (
      <div className="warning-container">
        <h1 className="warning-title">
          Important: Browser Settings Update Needed
        </h1>
        <p className="warning-text">
          To ensure this site functions correctly, please enable cookies or
          storage settings in your browser. For users accessing this site within
          an iframe, make sure cross-site cookies are allowed. Additionally, we
          recommend updating your browser to the latest version for optimal
          compatibility. These settings and updates are necessary for providing
          the best experience and full functionality of the site.
        </p>
      </div>
    );
  }

  return (
    <ConfigProvider
      theme={{
        cssVar: true,
        token: {
          colorPrimary: primaryColor,
          colorText: '#72788f',
          fontFamily: 'Figtree, sans-serif',
          lineHeight: 1.3,
        },
        components: {
          Input: {
            colorText: '#15161b',
          },
          Select: {
            colorText: '#15161b',
            controlHeight: '44px',
            optionHeight: '32px',
          },
          Modal: {
            paddingContentHorizontal: 0,
            paddingContentHorizontalLG: 0,
            paddingContentHorizontalSM: 0,
            paddingContentVertical: 0,
            paddingContentVerticalLG: 0,
            paddingContentVerticalSM: 0,
          },
          Button: {
            colorLink: primaryColor,
            colorLinkActive: primaryColor,
            colorLinkHover: primaryColor,
          },
          Layout: {
            bodyBg: '#ffffff',
          },
        },
      }}
    >
      <Sentry.ErrorBoundary fallback={MyFallbackComponent}>
        <BrowserRouter history={history}>
          <Routes>
            <Route
              path={ROUTES?.MAIN} // the matching param will be available to the loader
              element={<Error404 />}
            />
            <Route
              path={`${ROUTES?.SLUG}/*`} // the matching param will be available to the loader
              element={<App />}
            />
          </Routes>
        </BrowserRouter>
      </Sentry.ErrorBoundary>
    </ConfigProvider>
  );
};
export default RoutesWrapper;
