import { useLazyQuery, useMutation } from '@apollo/client';
import {
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { Button, Card, Form, Progress } from 'antd';
import {
  cloneDeep,
  filter,
  find,
  findIndex,
  forEach,
  isArray,
  isEmpty,
  kebabCase,
  map,
  remove,
  sortBy,
  uniq,
  uniqBy,
} from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AppContext } from '../AppContext';
import {
  CONDITIONS_KEYS,
  DYNAMIC_FIELDS_TYPE,
  FIELD_CATEGORY,
  RESERVE_APPOINTMENT_FROM,
  SPONSOR_KEY,
  TENANT_KEY,
  WIDGET_TYPES,
} from '../common/constants';
import IframeHeightCommunicator from '../common/iframeHeightCommunicator';
import {
  createCustomFieldObject,
  createLeadTracking,
  createMetaObj,
  createSystemFieldObject,
  formatPhoneNumberWithoutMask,
  googleAnalyticsParams,
  isPageExist,
  isPageSkip,
  updateDataMutation,
  uploadPublicFiles,
} from '../common/utils';
import {
  CREATE_LEAD_V2,
  CREATE_PARTIAL_LEAD,
  DELETE_PROJECT_FROM_POSTAL,
} from '../graphql/Mutation';
import {
  GET_LEAD_STORE_DATA,
  GET_PAGE_SEQUENCES,
  VALIDATE_ZIP_CODE,
} from '../graphql/Query';
import { messageContext } from './AppContextHolder';
import FooterSection from './FooterSection';
import GeneralSection from './GeneralSection';
import LoaderComponent from './LoaderComponent';
import WidgetSection from './WidgetSection';
import './styles/CommonConfigComponent.less';

function RedirectProgress({ onComplete }) {
  const intervalId = useRef(null);
  const [progress, setProgress] = useState(0);
  const { dispatch } = useContext(AppContext);
  const percent = progress / 30;

  useEffect(() => {
    intervalId.current = setInterval(() => {
      setProgress((prev) => prev + 100);
    }, 100);

    return () => {
      clearInterval(intervalId.current);
    };
  }, [dispatch]);

  useEffect(() => {
    if (percent === 30) {
      // eslint-disable-next-line no-undef
      const wsmTracker = window?.Wsm?.getAsyncTracker();

      if (wsmTracker) {
        wsmTracker?.deleteCookies();
        wsmTracker?.removeAllTrackers();
      }

      // eslint-disable-next-line no-undef
      document.cookie.split(';').forEach((c) => {
        // eslint-disable-next-line no-undef
        document.cookie = c
          .replace(/^ +/, '')
          .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
      });
    }
    if (percent === 100) {
      clearInterval(intervalId.current);
      if (onComplete) {
        onComplete();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [percent]);

  return (
    <>
      <div className="mb-16">
        <Progress status="success" percent={percent} showInfo={false} />
      </div>
      <p>You'll be sent to a new page soon. If not, click the button below.</p>
      <Button disabled={percent !== 100} onClick={onComplete}>
        Click here to redirect
      </Button>
    </>
  );
}

const CommonConfigComponent = ({
  pageSequencesData = [],
  setPageSequencesData,
  pageKey = null,
  title,
  setCurrentPage,
}) => {
  const {
    dispatch,
    state: { storedData, totalProject, isSponsor, subDomain },
  } = useContext(AppContext);
  const navigate = useNavigate();
  const portalIdentifier = isSponsor ? SPONSOR_KEY : TENANT_KEY;
  const { slug } = useParams();
  const stripe = useStripe();
  const elements = useElements();
  const [form] = Form?.useForm();
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [currentPageSequence, setCurrentPageSequence] = useState(null);
  const [nextPageObj, setNextPageObj] = useState(null); // if ever face a issue with next Page object, just check your pagesequence. each page should have correct page order (index +1)
  const [savedData, setSavedData] = useState(null);
  const [primarySkuProduct, setPrimarySkuProduct] = useState([]);
  const [redirectButtonClicked, setRedirectButtonClicked] = useState(false);
  const location = useLocation();
  const { pathname = '', state = '' } = location;
  const queryParams = state?.urlParams;

  const equals = (a, b) => JSON?.stringify(a) === JSON?.stringify(b);

  const [createLeadV2] = useMutation(CREATE_LEAD_V2, {
    onError() {
      setSubmitLoading(false);
    },
  });

  const [createPartialLead] = useMutation(CREATE_PARTIAL_LEAD, {
    onError() {
      setSubmitLoading(false);
    },
  });

  const [validateZipCode] = useLazyQuery(VALIDATE_ZIP_CODE, {
    fetchPolicy: 'network-only',
    onCompleted() {},
    onError() {},
  });

  const [getLeadStoreData] = useLazyQuery(GET_LEAD_STORE_DATA, {
    fetchPolicy: 'network-only',
  });

  const [secondaryPageSequences] = useLazyQuery(GET_PAGE_SEQUENCES, {
    fetchPolicy: 'network-only',
    onCompleted: async (res) => {
      const queryParamsKey = (queryParams && Object?.keys(queryParams)) || [];
      let systemFields;
      let customFields;
      let allowedPages;
      let metaData = storedData?.metaData || null;

      // eslint-disable-next-line no-undef
      const wsmTracker = window?.Wsm?.getAsyncTracker();
      const visitorId = await wsmTracker?.getVisitorUniqueId();
      const isZipCodeValid =
        queryParams?.isZipCodeValid || storedData.isZipCodeValid;

      const secondaryData = map([...res?.pageSequences?.data], (item) => {
        const widgetType = item?.pageConfiguration.widgetType;
        const pageKeyClone = item?.pageKey;
        const isPageAlwaysSkip =
          item?.pageConfiguration?.skipPageConditions?.alwaysSkip;
        const skipPageConditions = item?.pageConfiguration?.skipPageConditions?.data?.filter(
          (skipPageCond) => skipPageCond?.check,
        );

        const isSkip = isPageSkip(
          isPageAlwaysSkip,
          skipPageConditions,
          customFields,
          systemFields,
        );
        if (!isEmpty(queryParams)) {
          if (isSkip) {
            return {
              ...item,
              isSecondaryPage: true,
              canSkip: true,
            };
          }
          if (widgetType === WIDGET_TYPES?.CONTENT) {
            metaData = {
              ...metaData,
              [pageKeyClone]: true,
            };
          }
          if (
            queryParamsKey?.includes(item?.pageKey) &&
            widgetType !== WIDGET_TYPES?.CONTENT // content pages are skipped
          ) {
            if (widgetType === FIELD_CATEGORY?.CUSTOM) {
              const newCustomField = createCustomFieldObject(item, queryParams);
              metaData = {
                ...metaData,
                [pageKeyClone]: createMetaObj(
                  newCustomField,
                  FIELD_CATEGORY?.CUSTOM,
                ),
              };
              customFields = {
                ...customFields,
                ...newCustomField,
              };
            }
            if (widgetType === FIELD_CATEGORY?.SYSTEM) {
              const newSystemField = createSystemFieldObject(item, queryParams);
              systemFields = {
                ...systemFields,
                ...newSystemField,
              };
              metaData = {
                ...metaData,
                [pageKeyClone]: createMetaObj(
                  newSystemField,
                  FIELD_CATEGORY?.SYSTEM,
                ),
              };

              if (newSystemField) {
                systemFields = {
                  ...systemFields,
                  ...newSystemField,
                };
              }
            }
          }
        }
        return {
          ...item,
          isSecondaryPage: true,
        };
      })
        ?.filter((page) => !page?.pageSkipFromParams)
        ?.filter((page) => !page?.canSkip);

      const pageSequenceDataCopy = cloneDeep(pageSequencesData)
        ?.map((page) => {
          if (
            page?.pageConfiguration?.widgetType === WIDGET_TYPES?.SUPPORT &&
            isZipCodeValid
          ) {
            return;
          }
          return {
            ...page,
          };
        })
        ?.filter((pageData) => pageData)
        ?.filter((item) => !item?.isSecondaryPage);

      const projectWidgetIndex = findIndex(
        pageSequenceDataCopy,
        (item) => item?.pageConfiguration?.widgetType === WIDGET_TYPES?.PROJECT,
      );

      const pagesBeforeProjectWidget = storedData?.allowedPages?.filter(
        (page, index) => index < projectWidgetIndex,
      );

      if (
        storedData?.navigateTo &&
        storedData?.navigateTo !== savedData?.navigateTo
      ) {
        allowedPages = [
          ...pagesBeforeProjectWidget,
          pageSequenceDataCopy[projectWidgetIndex],
        ];
      } else {
        allowedPages = [...storedData?.allowedPages];
      }

      // for normal flow. if there is a secondary questionnaire attached , will execute below IF statement
      if (secondaryData?.length > 0) {
        // adding secondary data after project widget
        pageSequenceDataCopy?.splice(
          projectWidgetIndex + 1,
          0,
          ...secondaryData,
        );
        setPageSequencesData(
          map(pageSequenceDataCopy, (item, index) => ({
            ...item,
            order: index + 1,
          })),
        );

        const data = {
          ...storedData,
          queryParamObject: queryParams, // query params added on store data as we use this to hide add new query projection button on cart page
          ...(systemFields && {
            systemFields,
          }),
          ...(customFields && {
            customFields,
          }),
          ...(metaData && {
            metaData,
          }),
          allowedPages,
          isSecondaryAdded: true,
        };
        await updateDataMutation(data, dispatch, visitorId);
        navigate(
          `/${slug}/${kebabCase(
            pageSequenceDataCopy?.[projectWidgetIndex + 1]?.pageKey,
          )}${googleAnalyticsParams(location?.state?.urlParams)}`,
          { state: location?.state },
        );
      } //  below statement will execute if all the pages are skip from url (secondaryData length will be 0 as all pages page key present in params).
      else if (queryParamsKey?.length > 0 && secondaryData?.length === 0) {
        const data = {
          ...storedData,
          queryParamObject: queryParams,
          ...(systemFields && {
            systemFields,
          }),
          ...(customFields && {
            customFields,
          }),
          allowedPages,
          isSecondaryAdded: true,
        };
        await updateDataMutation(data, dispatch, visitorId);
        navigate(
          `/${slug}/${kebabCase(nextPageObj?.pageKey)}${googleAnalyticsParams(
            location?.state?.urlParams,
          )}`,
          {
            state: location?.state,
          },
        );
      }
      // going in else if selected sub area is not changed.
      else {
        navigate(
          `/${slug}/${kebabCase(nextPageObj?.pageKey)}${googleAnalyticsParams(
            location?.state?.urlParams,
          )}`,
          {
            state: location?.state,
          },
        );
      }
    },
    onError: () => {
      dispatch({
        type: 'SET_LOADING',
        data: false,
      });
    },
  });

  const [deleteProject] = useMutation(DELETE_PROJECT_FROM_POSTAL, {
    onError() {},
  });

  useEffect(() => {
    async function getData() {
      // eslint-disable-next-line no-undef
      const wsmTracker = window?.Wsm?.getAsyncTracker();
      const visitorId = await wsmTracker?.getVisitorUniqueId();
      const { data } = await getLeadStoreData({
        variables: {
          where: {
            visitorId,
          },
        },
      });

      if (data?.getLeadStoreData?.data) {
        setPrimarySkuProduct(
          data?.getLeadStoreData?.data
            ?.filter((item) => item?.data?.productItemId)
            .map(
              (item) =>
                [
                  ...(item?.data?.products?.good?.productItemInfo ?? []),
                  ...(item?.data?.products?.better?.productItemInfo ?? []),
                  ...(item?.data?.products?.best?.productItemInfo ?? []),
                ]?.find((items) => items?.id === item?.data?.productItemId)
                  ?.sku,
            ),
        );
      }
    }
    if (
      !nextPageObj &&
      currentPageSequence?.pageConfiguration?.redirectUrlEnabled
    ) {
      getData();
      setRedirectButtonClicked(true);
    }
  }, [
    currentPageSequence?.pageConfiguration?.redirectUrlEnabled,
    getLeadStoreData,
    nextPageObj,
  ]);

  useEffect(() => {
    async function trackLead() {
      const currentPageSequenceCopy = find(
        pageSequencesData,
        (item) =>
          pathname?.substring(pathname?.lastIndexOf('/') + 1) ===
          kebabCase(item?.pageKey),
      );
      const userId = isSponsor
        ? currentPageSequenceCopy?.sponsorId
        : currentPageSequenceCopy?.tenantId;

      forEach(pageSequencesData, (item, index) => {
        if (currentPageSequenceCopy?.order + 1 === item?.order) {
          if (item?.pageConfiguration?.widgetType === WIDGET_TYPES?.CONTACT) {
            setNextPageObj(pageSequencesData?.[index + 1]);
            return;
          }
          setNextPageObj(item);
        }
      });
      // initialize tracker
      // eslint-disable-next-line no-undef
      const wsmTracker = await window?.Wsm?.getAsyncTracker();

      // we are not getting primary settings modal data for secondary, so taking that data for secondary questionnaire from project page
      // also verify in current page if  primary settings data is already present or not as we receiving primary settings data in content route if
      // lob and SA added in URL

      if (currentPageSequenceCopy?.isSecondaryPage) {
        wsmTracker?.setRoqPathId(currentPageSequenceCopy?.questionnaireId);
        const firstPrimaryPage = pageSequencesData?.find(
          (page) => !page.isSecondaryPage,
        );
        setCurrentPageSequence({
          ...currentPageSequenceCopy,
          ...(!currentPageSequenceCopy?.brandConfig && {
            brandConfig: firstPrimaryPage?.brandConfig,
          }),
          ...(!currentPageSequenceCopy?.cutOffTime && {
            cutOffTime: firstPrimaryPage?.cutOffTime,
          }),
          ...(!currentPageSequenceCopy?.tenantLogo && {
            tenantLogo: firstPrimaryPage?.tenantLogo,
          }),
          ...(!currentPageSequenceCopy?.logo && {
            logo: firstPrimaryPage?.logo,
          }),
          ...(!currentPageSequenceCopy?.primaryColor && {
            primaryColor: firstPrimaryPage?.primaryColor,
          }),
          privacyPolicy: firstPrimaryPage?.privacyPolicy,
          termsOfUse: firstPrimaryPage?.termsOfUse,
        });
      } else {
        setCurrentPageSequence(currentPageSequenceCopy);
        wsmTracker?.setRoqPathId(null);
      }

      // eslint-disable-next-line no-undef
      if (window?.Wsm?.initialized) {
        wsmTracker?.setCookiePath(slug);
        wsmTracker?.setPageTitle(currentPageSequenceCopy?.pageKey);
        if (storedData?.zipCode) {
          wsmTracker?.setZipCode(storedData?.zipCode);
        }
        if (storedData?.serviceTypeObject && storedData?.lobObject) {
          const projectWidgetObject = {
            subAreaId: storedData?.serviceTypeObject?.blockId,
            lineOfBusinessKey: storedData?.lobObject?.blockKey,
          };
          wsmTracker?.setProjectData(projectWidgetObject);
        }
        wsmTracker?.setSlug(slug);
        wsmTracker?.setPortalIdentifier(portalIdentifier);
        wsmTracker?.setSubDomain(subDomain);
        wsmTracker?.setTenantId(userId);
        wsmTracker?.setTrackerUrl(process.env.REACT_APP_ANALYTICS_URL);

        wsmTracker?.trackPageView();
        wsmTracker?.enableHeartBeatTimer();
        wsmTracker?.enableLinkTracking();
      }
      let data = null;

      if (isEmpty(storedData)) {
        let firstPageObj;
        if (queryParams?.isZipCodeValid) {
          firstPageObj = pageSequencesData?.filter(
            (page) =>
              page?.pageConfiguration?.widgetType !== WIDGET_TYPES?.SUPPORT,
          )?.[0];
        } else {
          firstPageObj = pageSequencesData?.[0];
        }

        data = {
          currentPage: firstPageObj?.pageKey,
          slug,
          currentOrder: firstPageObj?.order,
          currentQuote: true,
          industryId: firstPageObj?.industryId,
          ...(!isEmpty(queryParams?.urlParams) && {
            urlParams: queryParams?.urlParams,
          }),
          subDomain,
          ...(queryParams?.systemFields && {
            systemFields: queryParams?.systemFields,
          }),
          ...(queryParams?.metaData && {
            metaData: queryParams?.metaData,
          }),
          ...(queryParams?.customFields && {
            customFields: queryParams?.customFields,
          }),
          ...(queryParams?.zipCode && {
            zipCode: queryParams?.zipCode,
          }),
          ...(queryParams?.['campaign-code'] && {
            campaignCode: queryParams?.['campaign-code'],
          }),
          ...(queryParams?.state && {
            state: queryParams?.state,
          }),
          ...(queryParams?.city && {
            city: queryParams?.city,
          }),
          ...(queryParams?.shortAddress && {
            shortAddress: queryParams?.shortAddress,
          }),
          ...(queryParams?.leadId && {
            leadId: queryParams?.leadId,
          }),
          ...(queryParams?.lobObject && {
            lobObject: {
              key: queryParams?.lobObject?.blockKey,
              ...queryParams?.lobObject,
            },
          }),
          ...(queryParams?.serviceTypeObject?.navigateTo && {
            navigateTo: queryParams?.serviceTypeObject?.navigateTo,
            isSecondaryAdded: true,
          }),
          ...(queryParams?.serviceTypeObject && {
            serviceTypeObject: {
              key: queryParams?.serviceTypeObject?.blockKey,
              ...queryParams?.serviceTypeObject,
            },
          }),
        };
      } else {
        if (storedData?.previousPageKey !== pageKey) {
          const currentQuoteObj = { ...storedData };

          const doesPageExist = currentQuoteObj?.allowedPages?.find(
            (page) => page?.pageKey === pageKey,
          );
          const updatedAllowedPages = [
            ...(isArray(currentQuoteObj?.allowedPages)
              ? currentQuoteObj?.allowedPages
              : []),
            // checking if page exist or not
            ...(doesPageExist
              ? [] // If currentPageSequenceCopy is already in allowedPages, add nothing
              : [
                  {
                    id: currentPageSequenceCopy?.id,
                    order: currentPageSequenceCopy?.order,
                    pageKey: currentPageSequenceCopy?.pageKey,
                    title: currentPageSequenceCopy?.title,
                  },
                ]),
          ];

          const allowedPreviousPageIndex = findIndex(
            updatedAllowedPages,
            (item) => item?.pageKey === pageKey,
          );

          const previousPageKey =
            updatedAllowedPages?.[allowedPreviousPageIndex - 1]?.pageKey;

          const previousPageTitle =
            updatedAllowedPages?.[allowedPreviousPageIndex - 1]?.title;

          data = {
            ...currentQuoteObj,
            //  kept for future use, NOTE :- use below code in CHANGE MY QUOTE flow if you want to fetch leadId ,visitorId, and url params from router state.
            // currently we are setting these parameters in leadStore data. for more check setCurrentPage() in contentRoute.js
            // ...(!isEmpty(queryParams) && {
            //   urlParams: queryParams
            // }),
            // ...(queryParams?.leadId && {
            //   leadId: queryParams?.leadId
            // }),
            // ...(queryParams?.visitorId && {
            //   visitorId: queryParams?.visitorId
            // }),
            currentPage: pageKey,
            slug,
            currentOrder: currentPageSequenceCopy?.order,
            ...(previousPageKey !== currentQuoteObj?.previousPageKey && {
              previousPageKey:
                previousPageKey ?? currentQuoteObj?.previousPageKey,
              previousPageTitle:
                previousPageTitle ?? currentQuoteObj?.previousPageTitle,
            }),
            // previousPageKey:
            //   currentQuoteObj?.allowedPages?.[allowedPreviousPageIndex - 1]
            //     ?.pageKey,
            // previousPageTitle:
            //   currentQuoteObj?.allowedPages?.[allowedPreviousPageIndex - 1]
            //     ?.title
            // previousPageKey: currentPageSequenceCopy?.pageKey,
            // previousPageTitle: currentPageSequenceCopy?.title
          };
        } else {
          const currentQuoteObj = { ...storedData };
          const allowedPreviousPageIndex = findIndex(
            currentQuoteObj?.allowedPages,
            (item) => item?.pageKey === currentQuoteObj?.previousPageKey,
          );

          let backBtnPreviousPageTitle = null;
          let backBtnPreviousPageKey = null;

          if (allowedPreviousPageIndex < 0) {
            const previousPageIndex = findIndex(
              pageSequencesData,
              (item) =>
                item?.pageKey ===
                currentQuoteObj?.allowedPages?.[allowedPreviousPageIndex - 1]
                  ?.pageKey,
            );
            data = {
              ...currentQuoteObj,
              previousPageKey:
                pageSequencesData?.[previousPageIndex - 1]?.pageKey,
              previousPageTitle:
                pageSequencesData?.[previousPageIndex - 1]?.title,
            };
          } else {
            backBtnPreviousPageKey =
              currentQuoteObj?.allowedPages?.[allowedPreviousPageIndex - 1]
                ?.pageKey;
            backBtnPreviousPageTitle =
              currentQuoteObj?.allowedPages?.[allowedPreviousPageIndex - 1]
                ?.title;

            const backBtnPreviousPageExist = isPageExist(
              pageSequencesData,
              backBtnPreviousPageKey,
            );

            if (!backBtnPreviousPageExist) {
              backBtnPreviousPageKey = pageSequencesData?.[0]?.pageKey;
              backBtnPreviousPageTitle = pageSequencesData?.[0]?.title;
            }
            data = {
              ...currentQuoteObj,
              currentPage: pageKey,
              slug,
              currentOrder: currentPageSequenceCopy?.order,
              previousPageKey: backBtnPreviousPageKey,
              previousPageTitle: backBtnPreviousPageTitle,
            };
          }
        }

        const projectWidgetObject = {
          subAreaId: storedData?.serviceTypeObject?.blockId,
          lineOfBusinessKey: storedData?.lobObject?.blockKey,
        };
        wsmTracker?.setProjectData(projectWidgetObject);
      }
      const visitorId = await wsmTracker?.getVisitorUniqueId();

      await updateDataMutation(data, dispatch, visitorId);

      setLoading(false);
    }
    dispatch({
      type: 'SET_SHOW_SUBMIT_BTN',
      data: true,
    });
    trackLead();

    return () => {
      if (typeof window !== 'undefined') {
        // eslint-disable-next-line no-undef
        const wsmTracker = window?.Wsm?.getAsyncTracker();
        wsmTracker?.disableHeartBeatTimer();
        wsmTracker?.disableLinkTracking();
        wsmTracker?.removeAllTrackers();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmitCta = async (values) => {
    setSubmitLoading(true);

    // eslint-disable-next-line no-undef
    const wsmTracker = await window?.Wsm?.getAsyncTracker();
    const visitorId = await wsmTracker?.getVisitorUniqueId();
    // for submit log visit track
    await wsmTracker?.setIsSubmit(true);
    await wsmTracker?.trackPageView();
    let storedDataClone = { ...storedData };

    let finalSystemFields = {};
    let finalCustomFields = {};
    let isZipCodeValid = storedDataClone?.isZipCodeValid || true;
    let nextPage = null;
    let addOnProducts = storedData?.addOnProducts || [];
    const filesToSend = {};
    let allowedPages = [];
    let metaData = storedDataClone?.metaData || {};
    const customUploadWidget =
      values?.customFields?.[currentPageSequence?.id]?.fileList;
    const findPages = filter(
      pageSequencesData,
      (item) => !item?.pageConfiguration?.skipPageConditions?.alwaysSkip,
    );

    // fetched project widget index for fetching previous pages
    const projectWidgetIndex = findIndex(
      findPages,
      (item) => item?.pageConfiguration?.widgetType === WIDGET_TYPES?.PROJECT,
    );

    const pagesBeforeProjectWidget = storedData?.allowedPages?.filter(
      (page, index) => index < projectWidgetIndex,
    );

    const currentPageIndex = findIndex(
      findPages,
      (o) => o?.id === currentPageSequence?.id,
    );
    const action = `Customer provided information for ${currentPageSequence?.pageConfiguration?.title} page`;

    if (values?.addOnProducts) {
      addOnProducts = Object?.keys(values?.addOnProducts)
        ?.map((item) => {
          if (values?.addOnProducts?.[item]?.selected) {
            return {
              id: item,
              quantity: Number(values?.addOnProducts?.[item]?.quantity),
            };
          }
          return null;
        })
        ?.filter((item) => item !== null);
    }

    if (
      currentPageSequence?.pageConfiguration?.widgetType ===
      WIDGET_TYPES?.POSTAL
    ) {
      try {
        const res = await validateZipCode({
          variables: {
            where: {
              zipCode: values?.zipCode,
              slug: storedDataClone?.slug,
              subDomain: storedDataClone?.subDomain,
              portalIdentifier,
            },
          },
        });
        if (res?.data) {
          isZipCodeValid = res?.data?.validateZipCode;
        }
      } catch (error) {
        return error;
      }

      // can delete all project if count greater than 0
      if (
        storedDataClone?.zipCode &&
        values?.zipCode !== storedDataClone?.zipCode
      ) {
        //  delete mutation here
        try {
          const deleteProjectsRes = await deleteProject({
            variables: {
              where: {
                visitorId,
              },
            },
          });

          if (deleteProjectsRes) {
            const nextPageConfig = pageSequencesData?.[currentPageIndex + 1];
            // check if new zipcode is valid or not
            if (isZipCodeValid) {
              if (
                nextPageConfig?.pageConfiguration?.widgetType ===
                WIDGET_TYPES?.SUPPORT
              ) {
                nextPage = pageSequencesData?.[currentPageIndex + 2];
              } else {
                nextPage = pageSequencesData?.[currentPageIndex + 1];
              }
            } else {
              nextPage = pageSequencesData?.find(
                (page) =>
                  page.pageConfiguration.widgetType === WIDGET_TYPES?.SUPPORT,
              );
            }
            const previousPages = filter(pageSequencesData, (page) => {
              if (page?.order <= currentPageSequence?.order) {
                return {
                  id: page?.id,
                  order: page?.order,
                  pageKey: page?.pageKey,
                  title: page?.title,
                };
              }
            });
            const newJourneyData = {
              ...savedData,
              ...(values && { ...values }),
              currentPage: currentPageSequence?.pageKey,
              slug,
              currentOrder: currentPageSequence?.order,
              currentQuote: true,
              industryId: currentPageSequence?.industryId,
              previousPageKey: currentPageSequence?.pageKey,
              previousPageTitle: currentPageSequence?.title,
              ...(storedDataClone?.leadId && {
                leadId: storedDataClone?.leadId,
              }),
              ...(storedDataClone?.firstName && {
                firstName: storedDataClone?.firstName,
              }),
              ...(storedDataClone?.lastName && {
                lastName: storedDataClone?.lastName,
              }),
              ...(storedDataClone?.email && {
                email: storedDataClone?.email,
              }),
              ...(storedDataClone?.street && {
                street: storedDataClone?.street,
              }),
              ...(storedDataClone?.mobileNumber && {
                mobileNumber: storedDataClone?.mobileNumber,
              }),
              addOnProducts,
              allowedPages: previousPages,
              subDomain,
              ...(location && { ...location?.state?.leadData }),
            };
            await updateDataMutation(newJourneyData, dispatch, visitorId);
            storedDataClone = { ...newJourneyData };

            if (storedDataClone?.leadId) {
              createLeadTracking(
                slug,
                pageKey,
                visitorId,
                storedDataClone?.leadId,
                action,
              );
            }
            getLeadStoreData({
              variables: {
                where: {
                  visitorId,
                },
              },
            });
            dispatch({
              type: 'SET_TOTAL_PROJECT_COUNT',
              data: 0,
            });
            setSubmitLoading(false);
            navigate(
              `/${slug}/${kebabCase(nextPage?.pageKey)}${googleAnalyticsParams(
                location?.state?.urlParams,
              )}`,
              {
                state: location?.state,
              },
            );
          }
        } catch (error) {
          return;
        }
      }
    }

    if (values?.systemFields) {
      map(values?.systemFields, (item, key) => {
        if (item?.NUMBER) {
          finalSystemFields = {
            ...finalSystemFields,
            [key]: {
              type: DYNAMIC_FIELDS_TYPE?.NUMBER,
              value: [`${item?.NUMBER?.value}`],
              pageId: currentPageSequence?.id,
              isFieldQueryable:
                currentPageSequence?.pageConfiguration?.widgetConfiguration
                  ?.isFieldQueryable,
              entityName:
                currentPageSequence?.pageConfiguration?.widgetConfiguration
                  ?.selectedSystemFieldBlock?.entityName,
              fieldCategory:
                currentPageSequence?.pageConfiguration?.widgetConfiguration
                  ?.selectedSystemFieldBlock?.fieldCategory,
            },
          };
        }
        if (item?.[DYNAMIC_FIELDS_TYPE?.TEXT]) {
          finalSystemFields = {
            ...finalSystemFields,
            [key]: {
              type: DYNAMIC_FIELDS_TYPE?.TEXT,
              value: [item?.[DYNAMIC_FIELDS_TYPE?.TEXT]?.value],
              pageId: currentPageSequence?.id,
              isFieldQueryable:
                currentPageSequence?.pageConfiguration?.widgetConfiguration
                  ?.isFieldQueryable,
              entityName:
                currentPageSequence?.pageConfiguration?.widgetConfiguration
                  ?.selectedSystemFieldBlock?.entityName,
              fieldCategory:
                currentPageSequence?.pageConfiguration?.widgetConfiguration
                  ?.selectedSystemFieldBlock?.fieldCategory,
            },
          };
        }
        if (item?.[DYNAMIC_FIELDS_TYPE?.PICK_LIST]) {
          let value;
          const checkForArray = Array?.isArray(
            item?.[DYNAMIC_FIELDS_TYPE?.PICK_LIST]?.value,
          );
          if (checkForArray) {
            const aryLength = item?.[
              DYNAMIC_FIELDS_TYPE?.PICK_LIST
            ]?.value?.filter((pickListItem) => pickListItem)?.length;

            if (aryLength > 0) {
              value = [...item?.[DYNAMIC_FIELDS_TYPE?.PICK_LIST]?.value];
            }
          } else if (item?.[DYNAMIC_FIELDS_TYPE?.PICK_LIST]?.value) {
            value = [item?.[DYNAMIC_FIELDS_TYPE?.PICK_LIST]?.value];
          } else {
            value = [];
          }
          finalSystemFields = {
            ...finalSystemFields,
            [key]: {
              type: DYNAMIC_FIELDS_TYPE?.PICK_LIST,
              value,
              pageId: currentPageSequence?.id,
              isFieldQueryable:
                currentPageSequence?.pageConfiguration?.widgetConfiguration
                  ?.isFieldQueryable,
              entityName:
                currentPageSequence?.pageConfiguration?.widgetConfiguration
                  ?.selectedSystemFieldBlock?.entityName,
              fieldCategory:
                currentPageSequence?.pageConfiguration?.widgetConfiguration
                  ?.selectedSystemFieldBlock?.fieldCategory,
            },
          };
        }
      });
    }

    if (values?.customFields) {
      map(values?.customFields, (item, key) => {
        let value;
        if (item) {
          value = isArray(item) ? item : [item];
        } else {
          value = [];
        }
        finalCustomFields = {
          ...finalCustomFields,
          [key]: value,
        };
      });
    }

    if (values || savedData) {
      if (
        currentPageSequence?.pageConfiguration?.widgetType ===
        WIDGET_TYPES?.POSTAL
      ) {
        metaData = {
          ...metaData,
          [pageKey]: values?.zipCode,
        };
      }
      if (
        currentPageSequence?.pageConfiguration?.widgetType ===
        WIDGET_TYPES?.PROJECT
      ) {
        metaData = {
          ...metaData,
          lob: savedData?.lobObject,
          sa: savedData?.serviceTypeObject,
        };
      }
      if (
        currentPageSequence?.pageConfiguration?.widgetType ===
        WIDGET_TYPES?.SYSTEM
      ) {
        const metaObject = createMetaObj(
          savedData?.systemFields || values?.systemFields,
          FIELD_CATEGORY?.SYSTEM,
        );
        const finalMetaDataObject = {
          ...metaData,
          [pageKey]: {
            ...metaObject,
            value: [
              metaObject?.[
                currentPageSequence?.pageConfiguration?.widgetConfiguration
                  ?.selectedSystemFieldBlock?.selectedSystemFieldType
              ]?.value,
            ],
            pageId: currentPageSequence?.id,
            isFieldQueryable:
              currentPageSequence?.pageConfiguration?.widgetConfiguration
                ?.isFieldQueryable,
            entityName:
              currentPageSequence?.pageConfiguration?.widgetConfiguration
                ?.selectedSystemFieldBlock?.entityName,
            fieldCategory:
              currentPageSequence?.pageConfiguration?.widgetConfiguration
                ?.selectedSystemFieldBlock?.fieldCategory,
          },
        };

        delete finalMetaDataObject?.[pageKey]?.[
          currentPageSequence?.pageConfiguration?.widgetConfiguration
            ?.selectedSystemFieldBlock?.selectedSystemFieldType
        ];

        metaData = finalMetaDataObject;
      }

      if (
        currentPageSequence?.pageConfiguration?.widgetType ===
        WIDGET_TYPES?.CUSTOM
      ) {
        metaData = {
          ...metaData,
          [pageKey]: createMetaObj(
            savedData?.customFields || values?.customFields,
            FIELD_CATEGORY?.CUSTOM,
          ),
        };
      }
      if (
        currentPageSequence?.pageConfiguration?.widgetType ===
        WIDGET_TYPES?.CONTENT
      ) {
        metaData = {
          ...metaData,
          [pageKey]: true,
        };
      }
    }

    // This is due to possibility of it coming as an array and an object(older records) as well
    if (customUploadWidget?.length > 0 && customUploadWidget) {
      const filesToUpload = [];
      const uploadedFiles = [];
      map(customUploadWidget, (file) => {
        if (!file?.url) {
          filesToUpload?.push(file);
        } else {
          uploadedFiles?.push(file);
        }
      });

      if (filesToUpload?.length > 0) {
        await Promise?.all([
          uploadPublicFiles({
            files: filesToUpload,
            slug,
            tenantId: currentPageSequence?.tenantId,
            fieldKey: currentPageSequence?.pageConfiguration?.id,
          }),
        ]).then((res) => {
          if (!isEmpty(res?.[0])) {
            filesToSend[currentPageSequence?.id] = [
              ...uploadedFiles,
              ...res?.[0],
            ];
          }
        });
      } else if (uploadedFiles?.length > 0) {
        filesToSend[currentPageSequence?.id] = [...uploadedFiles];
      }
    }

    if (
      currentPageSequence?.pageConfiguration?.widgetType ===
      WIDGET_TYPES?.CUSTOMER_INFO
    ) {
      const finalDataObj = {
        firstName: values?.firstName,
        lastName: values?.lastName,
        email: values?.email,
        leadSource: 'ROQ',
        industryId: storedDataClone?.industryId,
        comments: values?.comments,
        mobileNumber: formatPhoneNumberWithoutMask(values?.mobileNumber),
        slug: storedDataClone?.slug,
        subDomain: storedDataClone?.subDomain,
        ...(storedDataClone?.leadId && { leadId: storedDataClone?.leadId }),
        location: {
          addressLine1: savedData?.street,
          city: storedDataClone?.city,
          state: storedDataClone?.state,
          country: 'USA',
          zipCode: storedDataClone?.zipCode,
        },
        ...(storedDataClone?.campaignCode && {
          campaignCode: storedDataClone?.campaignCode,
        }),
        ...(storedDataClone?.urlParams && {
          urlParams: storedDataClone?.urlParams,
        }),
        pageLevel: currentPageSequence?.pageConfiguration?.widgetType,
        visitorId,
      };

      const response = await createPartialLead({
        variables: { data: finalDataObj },
      });

      if (response?.data?.createPartialLead) {
        setSubmitLoading(false);

        storedDataClone = {
          ...storedDataClone,
          leadId: response?.data?.createPartialLead?.data?.id,
        };
        dispatch({
          type: 'SET_STORED_DATA',
          data: {
            ...storedData,
            leadId: response?.data?.createPartialLead?.data?.id,
          },
        });
      }
    }

    if (
      currentPageSequence?.pageConfiguration?.widgetType ===
        WIDGET_TYPES?.APPOINTMENT ||
      currentPageSequence?.pageConfiguration?.widgetType ===
        WIDGET_TYPES?.CONTACT ||
      currentPageSequence?.pageConfiguration?.widgetType ===
        WIDGET_TYPES?.SUPPORT
    ) {
      setSubmitLoading(true);
      let paymentMethodId;
      if (savedData?.selectedStep === 1) {
        if (!stripe || !elements) {
          setSubmitLoading(false);
          // Stripe.js has not yet loaded.
          // Make sure to disable form submission until Stripe.js has loaded.
          return;
        }

        const result = await stripe?.createPaymentMethod({
          type: 'card',
          card: elements?.getElement(CardNumberElement),
          billing_details: {
            name: `${values?.firstName} ${values?.lastName}`,
            email: values?.email,
          },
        });

        if (result?.error) {
          // Show error in payment form
          messageContext?.error(result?.error?.message);
          setSubmitLoading(false);
          return;
        }
        paymentMethodId = result?.paymentMethod?.id;
      }
      forEach(storedDataClone, (item) => {
        const itemCloneObj = { ...item };
        if (savedData?.lobObject) {
          if (itemCloneObj?.lobObject?.key !== savedData?.lobObject?.key) {
            itemCloneObj.dimension = null;
          }
        }
      });

      const finalDataObj = {
        portalIdentifier,
        firstName: values?.firstName || savedData?.firstName,
        lastName: values?.lastName || savedData?.lastName,
        email: values?.email || savedData?.email,
        leadSource: 'ROQ',
        industryId: storedDataClone?.industryId,
        comments: values?.comments || savedData?.comments,
        mobileNumber:
          formatPhoneNumberWithoutMask(values?.mobileNumber) ||
          savedData?.mobileNumber,
        slug: storedDataClone?.slug,
        subDomain: storedDataClone?.subDomain,
        ...(storedDataClone?.leadId && { leadId: storedDataClone?.leadId }),
        location: {
          addressLine1: savedData?.street,
          city: storedDataClone?.city,
          state: storedDataClone?.state,
          country: 'USA',
          zipCode: storedDataClone?.zipCode,
        },
        ...(storedDataClone?.campaignCode && {
          campaignCode: storedDataClone?.campaignCode,
        }),
        ...(storedDataClone?.urlParams && {
          urlParams: storedDataClone?.urlParams,
        }),
        pageLevel: currentPageSequence?.pageConfiguration?.widgetType,
        visitorId,

        // only send data from contact page
        ...(currentPageSequence?.pageConfiguration?.widgetType ===
          WIDGET_TYPES?.CONTACT && {
          emailMeInfo: {
            urgent: values?.urgent,
            emailConfirmation: values?.emailConfirmation,
          },
          contactMeInfo: {
            createdAt: moment(),
            timezone: Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone,
          },
          reserveAppointmentFrom: RESERVE_APPOINTMENT_FROM?.CONTACT,
        }),
        // only send data from support page
        ...(currentPageSequence?.pageConfiguration?.widgetType ===
          WIDGET_TYPES?.SUPPORT && {
          emailMeInfo: {
            urgent: values?.urgent,
            emailConfirmation: values?.emailConfirmation,
          },
          contactMeInfo: {
            createdAt: moment(),
            timezone: Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone,
          },
          reserveAppointmentFrom: RESERVE_APPOINTMENT_FROM?.CONTACT,
        }),

        // only send data from appointment page
        ...(currentPageSequence?.pageConfiguration?.widgetType ===
          WIDGET_TYPES?.APPOINTMENT && {
          paymentMethodId,
          // Condition added when there are no slots and schedule time would be the date selected on the calender
          scheduleTime:
            savedData?.scheduleTime?.selectedTime?.selectedSlots?.length > 0
              ? savedData?.scheduleTime?.selectedTime?.selectedSlots?.map(
                  (item) => ({
                    date: item?.date,
                    timezone: Intl?.DateTimeFormat()?.resolvedOptions()
                      ?.timeZone,
                    startTime: item?.from,
                    endTime: item?.to,
                    label: item?.label,
                  }),
                )
              : [
                  {
                    date: savedData?.scheduleTime?.date,
                    timezone: Intl?.DateTimeFormat()?.resolvedOptions()
                      ?.timeZone,
                    startTime: savedData?.scheduleTime?.from,
                    endTime: savedData?.scheduleTime?.to,
                    label: savedData?.label,
                  },
                ],
          contactMethods: values?.contactMethods || savedData?.contactMethods,
          reserveAppointmentFrom: paymentMethodId
            ? RESERVE_APPOINTMENT_FROM?.CARD
            : RESERVE_APPOINTMENT_FROM?.CONTACT,
        }),
      };

      if (storedDataClone?.leadId) {
        createLeadTracking(
          slug,
          pageKey,
          visitorId,
          storedDataClone?.leadId,
          action,
          finalDataObj,
        );
      }

      const finalUpdateDataObj = {
        ...storedDataClone,
        firstName: values?.firstName,
        lastName: values?.lastName,
        email: values?.email,
        comments: values?.comments,
        mobileNumber: formatPhoneNumberWithoutMask(values?.mobileNumber),
        street: savedData?.street,
      };

      await updateDataMutation(finalUpdateDataObj, dispatch, visitorId);
      const response = await createLeadV2({
        variables: { data: finalDataObj },
      });
      if (response?.data?.createLeadV2) {
        setSubmitLoading(false);

        if (
          currentPageSequence?.pageConfiguration?.widgetType ===
          WIDGET_TYPES?.SUPPORT
        ) {
          const lastPage = pageSequencesData?.[pageSequencesData?.length - 1];
          navigate(
            `/${slug}/${kebabCase(lastPage?.pageKey)}${googleAnalyticsParams(
              location?.state?.urlParams,
            )}`,
            {
              state: location?.state,
            },
          );
        } else {
          navigate(
            `/${slug}/${kebabCase(nextPageObj?.pageKey)}${googleAnalyticsParams(
              location?.state?.urlParams,
            )}`,
            {
              state: location?.state,
            },
          );
        }
      }
      return;
    }

    // if subarea changed, we are updating 'allowedPages' for fixing back button issue #DRF-7514
    if (
      storedDataClone?.navigateTo &&
      savedData?.navigateTo &&
      storedDataClone?.navigateTo !== savedData?.navigateTo
    ) {
      allowedPages = [...pagesBeforeProjectWidget];
    } else {
      allowedPages = storedDataClone?.allowedPages
        ? sortBy(
            uniqBy([...storedDataClone?.allowedPages, ...allowedPages], 'id'),
            (items) => items?.order,
          )
        : sortBy(uniqBy([...allowedPages], 'id'), (items) => items?.order);
    }

    const currentPageData = {
      id: currentPageSequence?.id,
      order: currentPageSequence?.order,
      pageKey: currentPageSequence?.pageKey,
      title: currentPageSequence?.title,
    };

    const pageIsAlreadyPushed = allowedPages?.find(
      (item) => item?.pageKey === currentPageSequence?.pageKey,
    );

    if (
      currentPageSequence?.pageConfiguration?.widgetType !==
        WIDGET_TYPES?.ADD_ON &&
      !pageIsAlreadyPushed
    ) {
      allowedPages?.push(currentPageData);
    } else if (!savedData?.skipAddOnProduct ?? !storedData?.skipAddOnProduct) {
      if (!pageIsAlreadyPushed) allowedPages?.push(currentPageData);
    }

    const isSecondaryAdded = storedDataClone?.isSecondaryAdded;
    const dataCloneObj = { ...storedDataClone };

    if (savedData?.lobObject) {
      if (dataCloneObj?.lobObject?.key !== savedData?.lobObject?.key) {
        dataCloneObj.dimension = null;
      }
    }

    const data = {
      ...dataCloneObj,
      ...savedData,
      ...(values && { ...values }),
      systemFields: {
        ...dataCloneObj?.systemFields,
        ...savedData?.systemFields,
        ...finalSystemFields,
      },
      customFields: {
        ...dataCloneObj?.customFields,
        ...savedData?.customFields,
        ...finalCustomFields,
        ...filesToSend,
      },
      previousPageKey: currentPageSequence?.pageKey,
      previousPageTitle: currentPageSequence?.title,
      isZipCodeValid,
      addOnProducts,
      allowedPages,
      metaData, // for REST API flow
    };

    if (storedDataClone?.leadId) {
      createLeadTracking(
        slug,
        pageKey,
        visitorId,
        storedDataClone?.leadId,
        action,
      );
    }

    await updateDataMutation(data, dispatch, visitorId);
    let nextPageKey = nextPageObj?.pageKey;

    // here need to check whether redirect to support widget or other currentPageIndex + 2
    if (isZipCodeValid) {
      const nextPageConfig = findPages?.[currentPageIndex + 1];

      if (
        nextPageConfig?.pageConfiguration?.widgetType === WIDGET_TYPES?.SUPPORT
      ) {
        nextPage = findPages?.[currentPageIndex + 2];
      } else if (
        nextPageConfig?.pageConfiguration?.widgetType ===
          WIDGET_TYPES?.ADD_ON &&
        (savedData?.skipAddOnProduct ?? storedData?.skipAddOnProduct)
      ) {
        nextPage = findPages?.[currentPageIndex + 2];
      } else {
        nextPage = findPages?.[currentPageIndex + 1];
      }
    } else {
      nextPage = findPages?.find(
        (page) => page.pageConfiguration.widgetType === WIDGET_TYPES?.SUPPORT,
      );
    }

    nextPageKey = nextPage?.pageKey;
    setNextPageObj(nextPage);
    const getNextPageConditions = filter(
      isArray(nextPage?.pageConfiguration?.skipPageConditions)
        ? nextPage?.pageConfiguration?.skipPageConditions
        : nextPage?.pageConfiguration?.skipPageConditions?.data,
      (item) => item?.check,
    );

    if (!isEmpty(getNextPageConditions)) {
      const getPagesIds = map(
        getNextPageConditions,
        (item) => item?.identifier,
      );
      const getPagesValues = {};
      if (!isEmpty(getPagesIds)) {
        if (!isEmpty(data?.customFields)) {
          map(getPagesIds, (id) => {
            if (data?.customFields?.[id]) {
              getPagesValues[id] = data?.customFields?.[id];
            }
          });
        }
        if (!isEmpty(data?.systemFields)) {
          map(data?.systemFields, (field) => {
            if (getPagesIds?.includes(field?.pageId)) {
              getPagesValues[field?.pageId] = field?.value;
            }
          });
        }
      }

      const skipPage = map(getNextPageConditions, (item) => {
        const pageValue = getPagesValues?.[item?.identifier];
        if (pageValue) {
          if (item?.condition === CONDITIONS_KEYS?.EQUAL_TO) {
            if (equals(pageValue, item?.value)) {
              return true;
            }
          }
          if (item?.condition === CONDITIONS_KEYS?.NOT_EQUAL_TO) {
            if (!equals(pageValue, item?.value)) {
              return true;
            }
          }
          if (item?.condition === CONDITIONS_KEYS?.CONTAINS) {
            if (item?.type === DYNAMIC_FIELDS_TYPE?.PICK_LIST) {
              const doesConditionMatch = map(pageValue, (pageItem) => {
                if (item?.value?.includes(pageItem)) {
                  return true;
                }
                return false;
              });

              if (doesConditionMatch?.includes(true)) {
                return true;
              }
            } else if (pageValue?.[0]?.includes(item?.value?.[0])) {
              return true;
            }
          }
          if (item?.condition === CONDITIONS_KEYS?.NOT_CONTAINS) {
            if (item?.type === DYNAMIC_FIELDS_TYPE?.PICK_LIST) {
              const doesConditionMatch = map(pageValue, (pageItem) => {
                if (item?.value?.includes(pageItem)) {
                  return true;
                }
                return false;
              });
              if (doesConditionMatch?.includes(true)) {
                return false;
              }
              return true;
            }
            if (!pageValue?.[0]?.includes(item?.value?.[0])) {
              return true;
            }
          }
          if (item?.condition === CONDITIONS_KEYS?.GREATER_LESS_RANGE) {
            if (item?.greaterThan?.check && item?.lessThan?.check) {
              if (
                pageValue?.[0] >= item?.greaterThan?.value &&
                pageValue?.[0] <= item?.lessThan?.value
              ) {
                return true;
              }
            } else if (item?.greaterThan?.check) {
              if (pageValue?.[0] > item?.greaterThan?.value) {
                return true;
              }
            } else if (item?.lessThan?.check) {
              if (pageValue?.[0] < item?.lessThan?.value) {
                return true;
              }
            }
          }
        }
        return false;
      });

      if (!skipPage?.includes(false)) {
        const findNextPage = findIndex(
          findPages,
          (o) => o?.id === nextPage?.id,
        );
        if (findNextPage !== -1) {
          setNextPageObj(findPages?.[findNextPage + 1]);
          nextPageKey = findPages?.[findNextPage + 1]?.pageKey;
        }
        const storedAllowedPages = storedDataClone?.allowedPages || [];
        const updatedAllowedPages = uniqBy(
          [...storedAllowedPages, ...allowedPages],
          'id',
        );
        remove(updatedAllowedPages, (o) => o?.id === nextPage?.id);
        const allSystemFields = {
          ...storedDataClone?.systemFields,
          ...savedData?.systemFields,
          ...finalSystemFields,
        };

        forEach(allSystemFields, (item, key) => {
          const systemPageId = item?.pageId;
          const allowed = updatedAllowedPages?.find(
            (allowedPage) => allowedPage?.id === systemPageId,
          );
          if (allowed) {
            finalSystemFields = {
              ...finalSystemFields,
              [key]: {
                ...item,
              },
            };
          }
        });

        const updatedData = {
          ...storedDataClone,
          ...savedData,
          ...(values && { ...values }),
          systemFields: {
            ...dataCloneObj?.systemFields,
            ...savedData?.systemFields,
            ...finalSystemFields,
          },
          customFields: {
            ...storedDataClone?.customFields,
            ...savedData?.customFields,
            ...finalCustomFields,
            ...filesToSend,
          },
          addOnProducts,
          previousPageKey: currentPageSequence?.pageKey,
          previousPageTitle: currentPageSequence?.title,
          allowedPages: updatedAllowedPages,
        };
        await updateDataMutation(updatedData, dispatch, visitorId);
      }
    }

    if (
      currentPageSequence?.pageConfiguration?.widgetType ===
      WIDGET_TYPES?.PROJECT
    ) {
      if (
        savedData?.navigateTo &&
        (storedDataClone?.navigateTo !== savedData?.navigateTo ||
          !isSecondaryAdded)
      ) {
        // check if user changed his subArea also that selected sub area has attached secondary questionnaire with it.
        try {
          const secondaryId = savedData?.navigateTo;
          secondaryPageSequences({
            variables: {
              where: {
                id: secondaryId,
                // TODO: Need to make this dynamic once the difference between sponsor  and tenant is distinguished
                portalIdentifier: 'TENANT',
                ...(queryParams && { urlParams: queryParams }),
              },
            },
          });
        } catch (error) {
          setSubmitLoading(false);
          return error;
        }
        return;
      }
      if (isSecondaryAdded && savedData?.navigateTo) {
        // execute below code if user doesn't change subarea.
        setSubmitLoading(false);
        navigate(
          `/${slug}/${kebabCase(nextPageKey)}${googleAnalyticsParams(
            location?.state?.urlParams,
          )}`,
          {
            state: location?.state,
          },
        );
        return;
      }
      if (queryParams?.lobObject && queryParams.serviceTypeObject) {
        // execute below code if user doesn't change subarea.
        setSubmitLoading(false);
        navigate(
          `/${slug}/${kebabCase(nextPageKey)}${googleAnalyticsParams(
            location?.state?.urlParams,
          )}`,
          {
            state: location?.state,
          },
        );
      } else {
        // selected service type has not attached any secondary questionnaire so we are removing all ready attached secondary pages.
        const pageSequenceDataCopy = cloneDeep(pageSequencesData)?.filter(
          (item) => !item?.isSecondaryPage,
        );

        setPageSequencesData(
          map(pageSequenceDataCopy, (item, index) => ({
            ...item,
            order: index + 1,
          })),
        );
        setNextPageObj(pageSequenceDataCopy?.[projectWidgetIndex + 1]);
        navigate(
          `/${slug}/${kebabCase(
            pageSequenceDataCopy?.[projectWidgetIndex + 1]?.pageKey,
          )}${googleAnalyticsParams(location?.state?.urlParams)}`,
          { state: location?.state },
        );
        return;
      }
    }
    setSubmitLoading(false);
    navigate(
      `/${slug}/${kebabCase(nextPageKey)}${googleAnalyticsParams(
        location?.state?.urlParams,
      )}`,
      {
        state: location?.state,
      },
    );
  };

  const handleBack = async () => {
    // eslint-disable-next-line no-undef
    const wsmTracker = await window?.Wsm?.getAsyncTracker();
    const visitorId = await wsmTracker?.getVisitorUniqueId();
    // for back button log visit track, need to set ping true for back button as discussed with sagar sir.
    await wsmTracker?.setIsBackPing(true);
    await wsmTracker?.trackPageView();
    const currentQuoteObj = { ...storedData };
    const allowedPreviousPageIndex = findIndex(
      currentQuoteObj?.allowedPages,
      (item) => item?.pageKey === currentQuoteObj?.previousPageKey,
    );
    let data;
    let previousPageKey = currentQuoteObj?.previousPageKey;
    let previousPageTitle = currentQuoteObj?.previousPageTitle;
    let backBtnPreviousPageTitle = null;
    let backBtnPreviousPageKey = null;
    if (allowedPreviousPageIndex < 0) {
      const previousPageIndex = findIndex(
        pageSequencesData,
        (item) => item?.pageKey === currentQuoteObj?.previousPageKey,
      );
      data = {
        ...currentQuoteObj,
        previousPageKey: pageSequencesData?.[previousPageIndex - 1]?.pageKey,
        previousPageTitle: pageSequencesData?.[previousPageIndex - 1]?.title,
      };
    } else {
      backBtnPreviousPageKey =
        currentQuoteObj?.allowedPages?.[allowedPreviousPageIndex - 1]?.pageKey;
      backBtnPreviousPageTitle =
        currentQuoteObj?.allowedPages?.[allowedPreviousPageIndex - 1]?.title;

      const backBtnPreviousPageExist = isPageExist(
        pageSequencesData,
        backBtnPreviousPageKey,
      );

      if (!backBtnPreviousPageExist) {
        backBtnPreviousPageKey = pageSequencesData?.[0]?.pageKey;
        backBtnPreviousPageTitle = pageSequencesData?.[0]?.title;
      }
      data = {
        ...currentQuoteObj,
        previousPageKey: backBtnPreviousPageKey,
        previousPageTitle: backBtnPreviousPageTitle,
      };
    }

    const isPreviousPageExist = isPageExist(
      pageSequencesData,
      storedData?.previousPageKey,
    );

    if (!isPreviousPageExist) {
      previousPageKey = currentQuoteObj?.allowedPages?.[0]?.pageKey;
      previousPageTitle = currentQuoteObj?.allowedPages?.[0]?.title;
    }
    const action = `Customer went back to the ${previousPageTitle} page`;

    if (storedData?.leadId) {
      createLeadTracking(slug, pageKey, visitorId, storedData?.leadId, action);
    }
    await updateDataMutation(data, dispatch, visitorId);

    navigate(
      `/${slug}/${kebabCase(previousPageKey)}${googleAnalyticsParams(
        location?.state?.urlParams,
      )}`,
      {
        state: location?.state,
      },
    );
  };

  const handleRedirectToContact = async () => {
    if (
      currentPageSequence?.pageConfiguration?.widgetType ===
      WIDGET_TYPES?.CONTACT
    ) {
      return;
    }

    // eslint-disable-next-line no-undef
    const wsmTracker = await window?.Wsm?.getAsyncTracker();
    const visitorId = await wsmTracker?.getVisitorUniqueId();
    // for contact button log visit track, need to set ping true for back contact button as discussed with sagar sir.
    await wsmTracker?.setIsBackPing(true);
    await wsmTracker?.trackPageView();

    const contactMePge = find(
      pageSequencesData,
      (item) => item?.pageConfiguration?.widgetType === WIDGET_TYPES?.CONTACT,
    );
    const allowedPages =
      storedData?.allowedPages?.length > 0 ? [...storedData?.allowedPages] : [];
    const currentPageData = {
      id: currentPageSequence?.id,
      order: currentPageSequence?.order,
      pageKey: currentPageSequence?.pageKey,
      title: currentPageSequence?.title,
    };
    allowedPages?.push(currentPageData);
    const data = {
      ...storedData,
      allowedPages,
      previousPageKey: currentPageSequence?.pageKey,
      previousPageTitle: currentPageSequence?.title,
    };

    const action = `Customer went to the contact page from ${currentPageSequence?.title} page`;

    if (storedData?.leadId) {
      createLeadTracking(slug, pageKey, visitorId, storedData?.leadId, action);
    }
    await updateDataMutation(data, dispatch, visitorId);
    navigate(
      `/${slug}/${kebabCase(contactMePge?.pageKey)}${googleAnalyticsParams(
        location?.state?.urlParams,
      )}`,
      {
        state: location?.state,
      },
    );
  };

  const handleClickCart = async () => {
    // need project data
    if (
      currentPageSequence?.pageConfiguration?.widgetType ===
        WIDGET_TYPES?.CART ||
      totalProject < 1
    ) {
      return;
    }

    // eslint-disable-next-line no-undef
    const wsmTracker = await window?.Wsm?.getAsyncTracker();
    const visitorId = await wsmTracker?.getVisitorUniqueId();
    // for cart button log visit track, need to set ping true for cart button as discussed with sagar sir.
    await wsmTracker?.setIsBackPing(true);
    await wsmTracker?.trackPageView();

    const action = `Customer went to the cart page from ${currentPageSequence?.title} page`;
    if (storedData?.leadId) {
      createLeadTracking(slug, pageKey, visitorId, storedData?.leadId, action);
    }
    const cartPage = find(
      pageSequencesData,
      (item) => item?.pageConfiguration?.widgetType === WIDGET_TYPES?.CART,
    );
    const data = {
      ...storedData,
      previousPageKey: currentPageSequence?.pageKey,
      previousPageTitle: currentPageSequence?.title,
    };

    await updateDataMutation(data, dispatch, visitorId);

    navigate(
      `/${slug}/${kebabCase(cartPage?.pageKey)}${googleAnalyticsParams(
        location?.state?.urlParams,
      )}`,
      {
        state: location?.state,
      },
    );
  };

  // Redirect logic
  const onRedirectComplete = async () => {
    const firstPageObj = pageSequencesData?.[0];

    navigate(`/${slug}/${kebabCase(firstPageObj?.pageKey)}`, { replace: true });

    // Redirect to the configured URL
    const redirectUrl = currentPageSequence?.pageConfiguration?.redirectUrl;
    if (
      currentPageSequence?.pageConfiguration?.redirectUrlEnabled &&
      redirectUrl
    ) {
      // eslint-disable-next-line no-undef
      document.cookie.split(';').forEach((c) => {
        // eslint-disable-next-line no-undef
        document.cookie = c
          .replace(/^ +/, '')
          .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
      });

      // eslint-disable-next-line no-undef
      const wsmTracker = window?.Wsm?.getAsyncTracker();

      if (wsmTracker) {
        wsmTracker?.deleteCookies();
        wsmTracker?.removeAllTrackers();
      }

      const url = new URL(
        redirectUrl?.startsWith('http')
          ? redirectUrl
          : `https://${redirectUrl}`,
      );

      if (url?.searchParams?.has('sku')) {
        // Finding Primary Product from all products
        const primaryProductsSku = uniq(primarySkuProduct).join(',');

        if (primaryProductsSku) {
          url?.searchParams?.set('sku', primaryProductsSku);
        }
      }

      dispatch({
        type: 'SET_STORED_DATA',
        data: {},
      });

      // eslint-disable-next-line no-undef
      window?.location?.reload();
      // eslint-disable-next-line no-undef
      window.location.href = url?.toString();
    } else {
      // eslint-disable-next-line no-undef
      const wsmTracker = window?.Wsm?.getAsyncTracker();

      if (wsmTracker) {
        wsmTracker?.deleteCookies();
        wsmTracker?.removeAllTrackers();
      }

      dispatch({
        type: 'SET_STORED_DATA',
        data: {},
      });

      // eslint-disable-next-line no-undef
      window?.location?.reload();
    }
  };

  const handleTrackExit = async () => {
    // eslint-disable-next-line no-undef
    const wsmTracker = await window?.Wsm?.getAsyncTracker();
    // for external link log visit track, need to set ping true for external link contact button as discussed with sagar sir.
    await wsmTracker?.setIsBackPing(true);
    await wsmTracker?.trackPageView();
  };

  if (loading) {
    return <LoaderComponent />;
  }

  return (
    <div className="common-config-component">
      <Helmet>
        <title>{title ?? 'path.pro'}</title>
      </Helmet>
      {/* added because of #DRF-9864 */}
      <IframeHeightCommunicator />
      <Card className="full-height-card card-body-padding horizontal-padding">
        <Form
          layout="vertical"
          autoComplete="off"
          name="common-form"
          form={form}
          onFinish={handleSubmitCta}
        >
          <GeneralSection
            pageConfiguration={currentPageSequence?.pageConfiguration}
            currentPageSequence={currentPageSequence}
            globalHeaderImage={
              currentPageSequence?.brandConfig?.headerImage?.url
            }
          />
          <WidgetSection
            pageConfiguration={currentPageSequence?.pageConfiguration}
            pageKey={pageKey}
            handleSubmitCta={handleSubmitCta}
            setSavedData={setSavedData}
            nextPageObj={nextPageObj}
            pageSequencesData={pageSequencesData}
            form={form}
            currentPageSequence={currentPageSequence}
            savedData={savedData}
            fieldConfig={currentPageSequence?.fieldConfig}
            defaultPickListOption={currentPageSequence?.defaultPickListOption}
            setCurrentPage={setCurrentPage}
          />
          {nextPageObj ? (
            <FooterSection
              currentPageSequence={currentPageSequence}
              submitLoading={submitLoading}
              handleBack={handleBack}
              handleRedirectToContact={handleRedirectToContact}
              handleClickCart={handleClickCart}
              pageSequencesData={pageSequencesData}
              handleTrackExit={handleTrackExit}
            />
          ) : (
            <div className="mt-16">
              <Button
                loading={redirectButtonClicked}
                onClick={async () => {
                  setRedirectButtonClicked(true);
                  // eslint-disable-next-line no-undef
                  const wsmTracker = window?.Wsm?.getAsyncTracker();
                  // for redirect button log visit track, need to set ping true for redirect button as discussed with sagar sir.
                  await wsmTracker?.setIsSubmit(true);
                  await wsmTracker?.trackPageView();
                }}
                className="common-button mb-16"
                id="home-btn"
                type="primary"
              >
                {currentPageSequence?.pageConfiguration?.redirectUrlEnabled
                  ? currentPageSequence?.pageConfiguration?.ctaButtonLabel
                  : 'Back to Homepage'}
              </Button>
              {redirectButtonClicked && (
                <RedirectProgress onComplete={onRedirectComplete} />
              )}
            </div>
          )}
        </Form>
      </Card>
    </div>
  );
};

export default CommonConfigComponent;
